(function (factory) {
  typeof define === 'function' && define.amd ? define(factory) :
  factory();
}(function () { 'use strict';

  window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };

  jQuery(document).ready(function($) {

      //////////////////////////////////////////////////
      ////////////////  SLICK SLIDER  //////////////////
      let duration = parseInt($('.slider-fade').data('duration'));
      $('.slider-fade').slick({
          dots: true,
          infinite: true,
          speed: 500,
          fade: true,
          cssEase: 'linear',
          arrows: false,
          autoplay: true,
          autoplaySpeed: duration,
      });

      function setSliderHeight(){
          // get viewport height
          const viewportHeight = $(window).height();
          // set slider height
          $('.section-hero').css('height', viewportHeight + 'px');
          // set slider overlay height
          let overlayHeight = viewportHeight - 20;
          $('#slider-overlay').css('height', overlayHeight + 'px');
      }
      setSliderHeight();

      //////////////////////////////////////////////////
      ////////////////  GSAP  //////////////////
      //////////////////////////////////////////////////


      // gsap.registerPlugin(ScrollTrigger, SplitText);
      gsap.registerPlugin(ScrollTrigger, SplitText, ScrollSmoother);

      //////////////////////////////////////////////////
      ////////////////  SCROLLING  //////////////////
      const mm = gsap.matchMedia();
      mm.add("(min-width: 768px)", () => {
          
          ScrollTrigger.normalizeScroll(true);

          let smoother = ScrollSmoother.create({
              wrapper: "#smooth-wrapper",
              content: "#smooth-content",
              smooth: 1,
              normalizeScroll: true,
              ignoreMobileResize: true,
              smoothTouch: false, 
              effects: true,
              // onUpdate: (self) => skewSetter(clamp(self.getVelocity() / -150)),
              // onStop: () => skewSetter(0)
            });

    
          const section3ImageAnim = gsap.utils.toArray('.section-3-image .img-cont');
          section3ImageAnim.forEach(imgAnim => {
              let pTrigger = imgAnim.closest('.section-3-image');
              pTrigger = imgAnim.closest('.img-wrap');
    
              let noffset = $(imgAnim).attr('data-svoffset');
              
              let upAnimtl = gsap.timeline({
                  scrollTrigger: {
                      trigger: pTrigger,
                      start: 'top bottom',
                      // end: () => `+=${pinnedElement.clientHeight}`,
                      end: 'top 5%',
                      // end: 'bottom 100%',
                      // pin: imgAnim,
                      pinSpacing: false,
                      markers: false, // For visualization (can be removed)
                      scrub: true,
                  },
              });
   
              upAnimtl.fromTo(
                  imgAnim,
                  {
                      y: noffset,
                  },
                  {
                      y: 0,
                      duration: 1,
                  }
              );

          });

          ////////////////////////////////////////////
          // 2 image section text pin
          const pinSectionTwo = $('.section-2-image');
          const textSectionTwo = $('.section-2-image .container-text');
          const pinEndTrigger = $('#img-past-fixed');
          gsap.to(textSectionTwo, {
              scrollTrigger: {
                  trigger: pinSectionTwo,
                  start: 'top top',
                  // end: () => `+=${pinnedElement.clientHeight}`,
                  endTrigger: pinEndTrigger, // Release pin when the last container is reached
                  end: 'top 20%',
                  pin: textSectionTwo,
                  pinSpacing: false,
                  markers: false, // For visualization (can be removed)
                  scrub: true,
              },
          });


          return () => smoother.kill();
      });


      //////////////////////////////////////////////////
      ////////////////  NAV BAR  //////////////////

      ///////////////////////////
      // nav bar pin top
      const pinnedElement = $('#primary-nav');
      gsap.to(pinnedElement, {
          scrollTrigger: {
              trigger: pinnedElement,
              start: 'top top',
              // end: () => `+=${pinnedElement.clientHeight}`,
              endTrigger: 'footer', // Release pin when the last container is reached
              end: 'bottom bottom',
              pin: true,
              pinSpacing: false,
              markers: false, // For visualization (can be removed)
          },
      });

      //////////////////////////
      // nav bar bg animation
      const primNav = $('#primary-nav');
      const navOffset = (primNav.outerHeight() / $(window).height() ) * 100 ;
      const navBgs = gsap.utils.toArray('.nav-bg');
      const threeImgSections = gsap.utils.toArray('.section-bg');
      let navBgTrigger;
      let navBgTl;
      navBgs.forEach((navBg, i) => {
          // textCont = pinSectionThree.querySelector('.container-text');
          // console.log(textCont)
          navBgTrigger = threeImgSections[i];
          navBgTl = gsap.timeline({
              scrollTrigger: {
                  trigger: navBgTrigger,
                  // start: 'top 1%',
                  start: 'top ' + navOffset + '%',
                  // end: () => `+=${pinnedElement.clientHeight}`,
                  end: 'top top',
                  scrub: true,
                  markers: false,
              },
          });
          navBgTl.fromTo(
              navBg,
              {
                  clipPath: "polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)",
              },
              {
                  clipPath: "polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%)",
                  duration: 1,
              }
          );

      });

      // animate primary nav visibility
      let navLeftTl = gsap.timeline({
          scrollTrigger: {
              trigger: '.section-2-image',
              // start: 'top 1%',
              start: 'top ' + navOffset + '%',
              // end: () => `+=${pinnedElement.clientHeight}`,
              end: 'top top',
              scrub: true,
              markers: false,
              // pin: true,
          },
      });
      navLeftTl.to(
          primNav,
          {
              clipPath: "polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%)",
              // duration: 1,
          }
      );

      // animate primary nav visibility
      let navBlueTlIn = gsap.timeline({
          scrollTrigger: {
              trigger: threeImgSections[1],
              // start: 'top 1%',
              start: 'top ' + navOffset + '%',
              // end: () => `+=${pinnedElement.clientHeight}`,
              end: 'top top',
              scrub: true,
              markers: false,
              // pin: true,
          },
      });
      navBlueTlIn.to(
          '.container-nav-2',
          {
              clipPath: "polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%)",
          }
      );

      // animate primary nav visibility
      let navBlueTlOut = gsap.timeline({
          scrollTrigger: {
              trigger: threeImgSections[2],
              // start: 'top 1%',
              start: 'top ' + navOffset + '%',
              // end: () => `+=${pinnedElement.clientHeight}`,
              end: 'top top',
              scrub: true,
              markers: false,
              // pin: true,
          },
      });
      navBlueTlOut.to(
          '.container-nav-2',
          {
              clipPath: "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)",
              // duration: 1,
          }
      );


      //////////////////////////////////////////////////
      ////////////////  HERO ANIMATION  //////////////////
      
      let tl;
      let sliderImgOpacity = '0.5';
      

      //////////////////////////////////////////////////
      // slider expand
      function on_load(){
          tl = gsap.timeline({ paused: false, defaults: {  } });
          tl.delay(.1); 
          // const container = document.querySelector('.slide-0')
          tl.to('.slide-0', { opacity: "1" }, ""); // expand
          tl.to('.slide-0', { clipPath: "polygon(0 0, 100% 0, 100% 100%, 0% 100%)" }, "+=.2"); // expand
          // tl.to('.slide-0', { opacity: "1" }, ""); // expand
          tl.to('.slide-0 img', { opacity: sliderImgOpacity }, "+=.1");
          // callCount++;
      }
      on_load();


      ////////////////////////////////////////
      // slider overlay animation
      // Initialize the animation
      function animateOverlay(){
          // make text visible after load
          document.getElementById('slider-overlay').style.opacity = "1";

          // declar vars
          const titleSplitTypes = document.querySelectorAll('.title-reveal-type');
          const title = new SplitText('#hero-title', {
              types: 'chars'
          });
          const logoIcon = document.querySelector('.logo-icon');
          const overlayTl = gsap.timeline();

          mm.add("(min-width: 768px)", () => {
              // set title left position
              const titleLeftPos = ( $(document).width() - $('#hero-title').width() ) / 2;
              $('#hero-title').css('left', titleLeftPos + 'px');
          });

          overlayTl.delay(.25);

          // animate icon in
          overlayTl.from(logoIcon, {
              // y: 30,
              opacity: 0,
              duration: .75,
              ease: 'power4.out'
          });

          // animate text up and in
          const titleTl = gsap.timeline();
          titleTl.from('#hero-title', {
              // y: 30,
              opacity: 0,
              // stagger: 0.05,
              duration: 1,
              ease: 'power4.out'
          });
          overlayTl.add(titleTl);

                  
          ///////////////////////////////////////////////////
          ////////////////  HERO LOGO  //////////////////

          mm.add("(min-width: 768px)", () => {
              ScrollTrigger.create({
                  // trigger: '#primary-nav',
                  trigger: '.section-2-image',
                  start: "top 80%",
                  end: "top " + navOffset + "%",
                  scrub: true,
                  markers: false,
                  // pin: true,
                  animation: gsap.to(logoIcon, {
                      width: '30px',
                  }
                  ),
              });
              ScrollTrigger.create({
                  trigger: '#logo-cont',
                  start: 'top top',
                  // end: 'bottom 100000%',
                  end: document.body.scrollHeight,
                  scrub: true,
                  markers: false,
                  pin: true,
                  pinSpacing: false,
              });
          });


          
          ///////////////////////////////////////////////////
          ////////////////  HERO TITLE  //////////////////

          let leftPos = $('.nav-left').offset().left;
          let startFontSize = $('#hero-title').css('font-size');
          let startLeftPos = $('#hero-title').offset().left;
          const heroTitle = document.getElementById('hero-title');

          mm.add("(min-width: 768px)", () => {

              ScrollTrigger.create({
                  trigger: '.section-2-image',
                  start: "top 80%",
                  // end: "bottom top",
                  end: "top " + navOffset + "%",
                  scrub: true,
                  markers: false,
                  animation: gsap.fromTo(heroTitle, {
                      fontSize: startFontSize,
                      left: startLeftPos + "px",
                  },
                  {
                      fontSize: "2rem",
                      left: leftPos + "px",
                      // top: '20px',
                  }),
              });

              ScrollTrigger.create({
                  trigger: '#hero-title-cont',
                  start: '55% top',
                  end: 'bottom -100%',
                  // end: 'bottom ' + '-' + navOffset + '%',
                  // end: 'bottom ' + navOffset + '%',
                  scrub: true,
                  markers: false,
                  pin: true,
                  pinSpacing: false,
              });
          });
      }
      // animateOverlay();
      setTimeout(animateOverlay, 2000);




  });

}));
